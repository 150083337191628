.App .who .row>div {
  padding: 140px 0 60px 0;
}

.App .who-text {
  text-align: left;
  padding-right: 20%;

  h2 {
    margin: 10px 0 30px;
  }
}

.App .what .row>div, .reach-out .row>div {
  padding: 60px 0;
}

.App .what-text {
  padding-right: 20%;
}

.App .copyright {
  font-size: 0.7rem;
}